import {
	Label,
	Table,
	Text,
	theme,
	Flex,
} from '@opsdti-global-component-library/amgen-design-system';
import NotificationsLoader from '../../../reusable/notifications-loader';
import sortData from '../../../../utils/sort-data';
import '../index.scss';

interface AdUsersTableProps {
	adUsers: any;
	loading: boolean;
}

const AdUsersTable = ({ adUsers, loading }: AdUsersTableProps) => {
	const { token } = theme;

	return (
		<>
			{loading ? (
				<NotificationsLoader />
			) : (
				<Flex vertical gap={8}>
					<Label
						style={{
							color: token?.colorText,
							marginTop: '4px',
							marginLeft: '1px',
						}}
					>
						{`${adUsers.length} users`}
					</Label>
					<Table
						key="manage-ad-users-table"
						bordered={false}
						showSorterTooltip={false}
						columns={[
							{
								dataIndex: 'fullname',
								key: 'user_name',
								sortDirections: ['descend', 'ascend', 'descend'],
								defaultSortOrder: 'ascend',
								title: (
									<Text strong className="subscriptions-table-column">
										USER NAME
									</Text>
								),
								sorter: (a, b, sortOrder) =>
									sortData(a?.fullname, b?.fullname, sortOrder),
								width: '142px',
								ellipsis: true,
							},
							{
								dataIndex: 'loginname',
								key: 'user_id',
								title: (
									<Text strong className="subscriptions-table-column">
										USER ID
									</Text>
								),
								width: '86px',
								ellipsis: true,
							},
							{
								dataIndex: 'position',
								key: 'position',
								title: (
									<Text strong className="subscriptions-table-column">
										POSITION
									</Text>
								),
								ellipsis: true,
							},
						]}
						dataSource={adUsers}
						scroll={{
							x: '300px',
							y: adUsers.length > 10 ? '350px' : undefined,
						}}
						size="small"
						style={{
							width: '434px',
						}}
					/>
				</Flex>
			)}
		</>
	);
};

export default AdUsersTable;
