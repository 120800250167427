import { useCallback, useEffect, useState } from 'react';
import {
	Button,
	Flex,
	Modal,
	StatusIcon,
	Text,
	theme,
} from '@opsdti-global-component-library/amgen-design-system';
import NotificationsBackButton from '../../reusable/notification-back-button';
import NotificationPreview from '../../reusable/notification-preview';
import PreviewModalChannel from './preview-modal-channel';
import PreviewModalFreq from './preview-modal-freq';
import PreviewModalSubheader from './preview-modal-subheader';
import { FetchNotificationResponse, SubscriptionAction } from '../../../types';
import './index.scss';

interface PreviewModalProps {
	notification: FetchNotificationResponse | undefined;
	open: boolean;
	userName: string;
	previewModalDismissed: () => void;
	openValidationModal: (
		action: SubscriptionAction,
		workstream: string,
		subscriberName?: string,
		notificationName?: string
	) => void;
	handleSubscription: (
		action: SubscriptionAction,
		notification: FetchNotificationResponse
	) => void;
	setOpenManageUserModal?: (value: boolean) => void;
}

const PreviewModal = ({
	notification,
	open,
	userName = '',
	previewModalDismissed,
	openValidationModal,
	handleSubscription,
	setOpenManageUserModal,
}: PreviewModalProps) => {
	const [checkedChannels, setCheckedChannels] = useState<string[]>([]);
	const [selectedFrequency, setSelectedFrequency] = useState('');
	const [subscriptionError, setSubscriptionError] = useState(false);
	const isADGoup = notification?.is_ad_group;

	const { token } = theme;

	const notificationPreviewDetails = notification?.notification_template
		? notification.notification_template.map((template) => {
				const channelName = template.channel_name?.S || template.channel.S;
				if (channelName.includes('teams')) {
					return { channel: 'MS Teams', templateContent: template.body?.S };
				} else if (channelName.includes('email')) {
					return { channel: 'Email', templateContent: template.body?.S };
				} else {
					return { channel: 'SMS', templateContent: template.body?.S };
				}
		  })
		: notification?.channel_id.map((channel) => {
				if (channel.includes('teams')) {
					return { channel: 'MS Teams', templateContent: '' };
				} else if (channel.includes('email')) {
					return { channel: 'Email', templateContent: '' };
				} else {
					return { channel: 'Email', templateContent: '' };
				}
		  });

	// set of operations to be performed when the modal is opened
	useEffect(() => {
		if (open) {
			if (notification) {
				setSelectedFrequency(notification?.frequency);
			}
			if (notification?.is_subscribed) {
				setCheckedChannels(
					notification.subscribed_channels.length > 0
						? notification.subscribed_channels
						: notification.channel_id
				);
			} else {
				setCheckedChannels([]);
			}
			setSubscriptionError(false);
		}
	}, [open, notification]);

	const scrollUserToChannelError = useCallback(() => {
		setTimeout(() => {
			const subscriptionErrorMessage = document.getElementById(
				'subscriptions-preview-modal-error-message'
			);
			if (subscriptionErrorMessage) {
				subscriptionErrorMessage.scrollIntoView({
					behavior: 'smooth',
					block: 'end',
					inline: 'nearest',
				});
			}
		}, 100);
	}, []);

	const handleSubscribeClick = useCallback(
		(notif: FetchNotificationResponse) => {
			if (checkedChannels.length === 0) {
				setSubscriptionError(true);
				scrollUserToChannelError();
			} else {
				setSubscriptionError(false);
				handleSubscription(SubscriptionAction.ADD_SUBSCRIBER, {
					...notif,
					selected_channels: checkedChannels,
					frequency: selectedFrequency,
					is_subscribed: true,
					is_enabled: true,
				});
				openValidationModal(
					SubscriptionAction.ADD_SUBSCRIBER,
					notif?.workstream
				);
			}
		},
		[
			checkedChannels,
			handleSubscription,
			openValidationModal,
			scrollUserToChannelError,
			selectedFrequency,
		]
	);

	const handleUpdateClick = useCallback(
		(notif: FetchNotificationResponse) => {
			if (checkedChannels.length === 0) {
				setSubscriptionError(true);
				scrollUserToChannelError();
			} else {
				setSubscriptionError(false);
				handleSubscription(SubscriptionAction.UPDATE_SUBSCRIBER, {
					...notif,
					selected_channels: checkedChannels,
					frequency: selectedFrequency,
				});
				openValidationModal(
					SubscriptionAction.UPDATE_SUBSCRIBER,
					notif?.workstream
				);
			}
		},
		[
			checkedChannels,
			handleSubscription,
			openValidationModal,
			scrollUserToChannelError,
			selectedFrequency,
		]
	);

	const previewModalFooter =
		!notification || !notificationPreviewDetails?.length ? null : (
			<Flex
				align="center"
				justify="center"
				className="subscriptions-preview-modal-footer"
				gap={8}
			>
				<Button
					className="subscriptions-preview-modal-cancel-button"
					text="CANCEL"
					onClick={previewModalDismissed}
					type="link"
					style={{ flex: '1' }}
				/>
				{!notification.is_subscribed ? (
					<Button
						className="subscriptions-preview-modal-subscribe-button"
						text="SUBSCRIBE"
						disabled={subscriptionError}
						onClick={() => handleSubscribeClick(notification)}
						type="primary"
						style={{ flex: '1' }}
					/>
				) : (
					<>
						<Button
							className="subscriptions-edit-modal-button"
							text="UNSUBSCRIBE"
							onClick={() => {
								const subscriberName = userName.split(', ').reverse().join(' ');

								previewModalDismissed();
								openValidationModal(
									SubscriptionAction.DELETE_SUBSCRIBER,
									notification?.workstream,
									subscriberName,
									notification?.name
								);
							}}
							type="secondary"
							style={{ flex: '1' }}
						/>
						{isADGoup ? (
							<></>
						) : (
							<Button
								className="subscriptions-edit-modal-button"
								disabled={subscriptionError}
								text="CONFIRM UPDATE"
								onClick={() => handleUpdateClick(notification)}
								type="primary"
								style={{ flex: '1' }}
							/>
						)}
					</>
				)}
			</Flex>
		);

	return !notification || !notificationPreviewDetails?.length ? null : (
		<Modal
			className="subscriptions-preview-modal"
			classNames={{ body: 'notifications-preview-modal-body-content' }}
			open={open}
			onCancel={() => {
				previewModalDismissed();
			}}
			title={`Sensing ${notification.workstream} Subscription - ${
				notification.is_subscribed ? 'Edit' : 'Subscribe'
			}`}
			footer={previewModalFooter}
			fixedHeight={true}
			height={527}
		>
			<>
				{setOpenManageUserModal && (
					<NotificationsBackButton
						text="BACK TO MANAGE USERS"
						onClick={() => {
							previewModalDismissed();
							setOpenManageUserModal(true);
						}}
						style={{
							marginBottom: '16px',
						}}
					/>
				)}
				<PreviewModalSubheader manageUserName={userName} />
				<NotificationPreview
					previewDetails={notificationPreviewDetails}
					title={notification?.name}
					style={{ marginTop: '12px' }}
				/>
				<PreviewModalFreq
					notification={notification}
					setSelectedFrequency={setSelectedFrequency}
				/>
				<PreviewModalChannel
					notification={notification}
					checkedChannels={checkedChannels}
					subscriptionError={subscriptionError}
					setSubscriptionError={setSubscriptionError}
					onChannelChange={setCheckedChannels}
				/>
				{subscriptionError ? (
					<Flex
						align="center"
						gap={8}
						id="subscriptions-preview-modal-error-message"
						className="subscriptions-preview-modal-error-message"
					>
						<StatusIcon status="error" height={16} width={16} />
						<Text>One channel selection required.</Text>
					</Flex>
				) : null}
				<Flex vertical gap={8} style={{ marginTop: '16px' }}>
					<Text strong className="subscriptions-preview-modal-trigger">
						Trigger
					</Text>
					<Flex>
						<Text
							className="subscriptions-preview-modal-trigger-detail"
							style={{ color: token?.colorTextLabel }}
						>
							{notification.arche_type}
						</Text>
					</Flex>
				</Flex>
			</>
		</Modal>
	);
};

export default PreviewModal;
