import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useUpdateNotificationsList } from '../../../hooks/use-update-notifications-list';
import { Modal } from '@opsdti-global-component-library/amgen-design-system';
import { fetchAdUsers } from '../../../services/fetch-ad-users';
import { fetchNotificationsList } from '../../../services/fetch-notifications';
import { AppContext } from '../../../state/app-context';
import ActiveSubscriptionsTable from './active-subscriptions-table';
import AdUsersTable from './ad-users-table';
import {
	FetchNotificationResponse,
	SubscriptionType,
	SubscriptionAction,
	UserAdGroupToggle,
	UserType,
} from '../../../types';
import './index.scss';

type ManageUsersModalProps = {
	open: boolean;
	closeModal: () => void;
	header?: string;
	selectedTab: string | number;
	data: any;
	handleButtonClick: (
		action: SubscriptionAction,
		selectedRow: FetchNotificationResponse | undefined
	) => void;
};

const ManageUsersModal: React.FC<ManageUsersModalProps> = ({
	open,
	closeModal,
	selectedTab,
	data,
	handleButtonClick,
}: ManageUsersModalProps) => {
	const { userRole } = useContext(AppContext);
	const [isFetchComplete, setIsFetchComplete] = useState(false);
	const [adUsers, setAdUsers] = useState<any>(undefined);
	const [enablePolling, setEnablePolling] = useState<boolean>(false);

	const { data: fetchedNotifications, refetch: refetchNotificationsList } =
		useQuery({
			queryKey: ['ManageList'],
			queryFn: fetchNotificationsList.bind(this, {
				data: {
					is_admin: true,
					is_delegatee: true,
					delegate_user_id: data?.userid,
					filters: {},
					subscribed_flag: true,
					delegate_user_type:
						selectedTab === UserAdGroupToggle.USER
							? UserType.USER
							: UserType.AD,
				},
				type: SubscriptionType.SUBSCRIBED,
				getAD: false,
			}),
			enabled: false,
			refetchOnWindowFocus: false,
		});

	const defaultTabs = [
		{
			key: '0',
			label: <span>Subscriptions</span>,
			children: (
				<ActiveSubscriptionsTable
					activeSubscriptions={fetchedNotifications}
					handleButtonClick={handleButtonClick}
					loading={!isFetchComplete || !fetchedNotifications}
					selectedTab={selectedTab}
				/>
			),
		},
		{
			key: '1',
			label: <span>Users</span>,
			children: (
				<AdUsersTable
					adUsers={adUsers}
					loading={!isFetchComplete || !adUsers}
				/>
			),
		},
	];

	useEffect(() => {
		if (open) {
			setIsFetchComplete(false);
			if (selectedTab === UserAdGroupToggle.USER) {
				refetchNotificationsList()
					.catch((error) => console.log(error))
					.finally(() => setIsFetchComplete(true));
			} else {
				Promise.all([
					refetchNotificationsList(),
					fetchAdUsers({
						ad_group: data?.full_name,
					}),
				])
					.then((responses) => {
						const [_, fetchAdUsersResponse] = responses;
						setAdUsers(fetchAdUsersResponse);
					})
					.catch((error) => console.log(error))
					.finally(() => setIsFetchComplete(true));
			}
		}
	}, [data, open, refetchNotificationsList, selectedTab, userRole]);

	useEffect(() => {
		// in every refetch we want to set the original list to the new data
		// and check if there are any pending notifications.
		// No polling required if there's no data.
		if (fetchedNotifications && fetchedNotifications.length > 0) {
			// check if there are remaining pending notifications
			const isPending = fetchedNotifications.some(
				(notification) => notification.is_processing
			);
			// if false disable polling
			setEnablePolling(isPending);
		}
	}, [fetchedNotifications]);

	const fetchNotificationsListMutation = useUpdateNotificationsList(
		'ManageList',
		setEnablePolling
	);

	useEffect(() => {
		const notificationListPolling = setInterval(() => {
			if (enablePolling) {
				fetchNotificationsListMutation.mutate({
					data: {
						is_admin: true,
						is_delegatee: true,
						delegate_user_id: data?.userid,
						filters: {},
						subscribed_flag: true,
						delegate_user_type:
							selectedTab === UserAdGroupToggle.USER
								? UserType.USER
								: UserType.AD,
					},
					type: SubscriptionType.SUBSCRIBED,
					getAD: false,
				});
			}
		}, 5000);
		return () => clearInterval(notificationListPolling);
	}, [
		data?.userid,
		enablePolling,
		fetchNotificationsListMutation,
		selectedTab,
	]);

	return (
		<Modal
			className="notifications-manage-users-modal"
			classNames={{ body: 'notifications-manage-users-modal-body-content' }}
			open={open}
			onCancel={() => {
				closeModal();
			}}
			title={`${
				selectedTab === UserAdGroupToggle.USER
					? data.full_name
							.split(', ')
							.sort(() => -1)
							.join(' ')
					: data.full_name
			} - Manage`}
			fixedHeight={true}
			height={588}
			tabList={selectedTab !== UserAdGroupToggle.USER ? defaultTabs : []}
			tabProps={{
				className: 'notifications-manage-modal-body-tab-content',
				tabBarStyle: { width: '152px' },
			}}
		>
			<>
				{selectedTab === UserAdGroupToggle.USER ? (
					<ActiveSubscriptionsTable
						activeSubscriptions={fetchedNotifications}
						handleButtonClick={handleButtonClick}
						loading={!isFetchComplete || !fetchedNotifications}
						selectedTab={selectedTab}
					/>
				) : null}
			</>
		</Modal>
	);
};

export default ManageUsersModal;
